html,
button,
input,
select,
textarea {
  color: #222;
}

body {
  font-size: 1.4em;
  line-height: 1.6;
  font-family: 'Adelle', 'Helvetica', 'Arial';
  background-color: #f1f1f1;
}

em {
  font-size: 0.8em;
}

a:link,
a:visited,
a:active {
  color: #105cb6;
  text-decoration: none;
}
a:hover {
  color: #222222;
  text-decoration: underline;
}

.container {
  padding: 20px 20px;
}

p {
  max-width: 580px;
}
.work {
  padding-top: 10px;
}

.work > ul {
  margin: 0;
  padding: 0;
  display: block;
  grid-template-columns: 1fr 1fr;
  /* flex-wrap: wrap; */
  gap: 40px;
}

.work > ul > li {
  margin: 4px 0;
  padding: 0;
  width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

li {
  list-style: none;
}

.work h3 {
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 0;
}

.work a img, .work video {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.details .role {
  font-weight: 600;
  font-size: 20px;
}

.details {
  font-size: 16px;
  padding: 0;
}

.social {
  position: absolute;
  top: 20px;
  right: 20px;
}

.papers ul {
  padding: 0 0 0 20px;
  margin: 0;
  font-size: 16px;
}

.papers li {
  list-style: auto;
}

@media only screen and (min-width: 1024px) {
  /* Style adjustments for viewports that meet the condition */
  .container {
    padding: 50px 100px;
    display: flex;
  }

  .bio {
    width: 50%;
  }
  

  .work {
    margin-left: 60px;
    flex: 1;
  }

  .work > ul {
    display: grid;
    grid-template-columns: 1fr;
  }
}


@media only screen and (min-width: 1440px) {
  /* Style adjustments for viewports that meet the condition */
  .container {
    padding: 50px 100px;
    display: flex;
  }

  .bio {
    width: 30%;
  }
  

  .work {
    margin-left: 60px;
    flex: 1;
  }

  .work > ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
